/* System Variables */
export const { HOLIDAYS_URL } = process.env;
export const { SITE_URL } = process.env;
export const { NEW_SITE_URL } = process.env;

export const { TRADING_PLATFORM_URL } = process.env;
export const { BACKEND_API } = process.env;

export const { GOOGLE_MAPS_API_KEY } = process.env;
export const { GOOGLE_MAPS_API_KEY_SOLAR } = process.env;
export const { GTM_ID } = process.env;

export const STANDARD_STROMEE_BUSINESS_CHANNEL = 'stromee';

/* Enums */
export const KEYBOARD_KEY_VALUES = {
    ENTER: 'Enter',
    ESCAPE: 'Escape',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_DOWN: 'ArrowDown',
    ARROW_UP: 'ArrowUp',
    HOME: 'Home',
    END: 'End',
    TAB: 'Tab',
    BACKSPACE: 'Backspace'
};

export const NODE_NAME = {
    INPUT: 'INPUT',
    LABEL: 'LABEL'
};

export const PAYMENT_METHODS = {
    debit: 'debit',
    transfer: 'transfer',
    cryptocurrency: 'cryptocurrency'
};

export const CRYPTO_CURRENCIES = {
    bitcoin: 'bitcoin',
    binanceToken: 'binanceToken'
};

export const DATE_PRESENT_FORMAT = 'MMM DD, YYYY';
export const DATE_EDIT_FORMAT = 'DD.MM.YYYY';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_WEEK_DAYS = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7
};

export const CUSTOMER_SPECIFICATION = {
    EARLIEST_DATE: 'earliest_possible_date',
    DESIRED_DATE: 'desired_date',
    TERMINATION: 'terminated_in_person',
    RELOCATION: 'relocation_at'
};

export const TERMINATION_PROCESS_DURATION = 12;

export const PRODUCER_STATUSES = {
    standard: 'standard',
    soldOut: 'sold out'
};

export const PRODUCER_TYPES = {
    wind: 'wind',
    solar: 'solar',
    water: 'water',
    biomass: 'biomass',
    default: 'default'
};

/* Constants */
export const CONSUMPTION_OPTIONS = [
    { value: '1600', label: '1 Person - 1600 kWh/Jahr ' },
    { value: '2400', label: '2 Personen - 2400 kWh/Jahr' },
    { value: '3200', label: '3 Personen - 3200 kWh/Jahr' },
    { value: '4200', label: '4 Personen - 4200 kWh/Jahr' }
];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const TYPES_OF_METER_ID = {
    COUNTER_NUMBER: 'counterNumber', // old one, non unique
    MARKET_LOCATION_IDENTIFIER: 'marketLocationIdentifier' // new one, unique
};

/* Labels */
export const ERRORS = {
    CALCULATE_TARIFF: 'Ungültige Postleitzahl.',
    CONSUMPTION_NO_TARIFF: 'Für diesen Verbrauch ist kein Tarif verfügbar.',
    POSTCODE_EMPTY: 'Bitte geben Sie die Postleitzahl ein.',
    CONSUMPTION_EMPTY: 'Bitte geben Sie den Verbrauch ein.',
    TARIFF_CALCULATION_FORM_EMPTY: 'Bitte erst PLZ und Verbrauch eingeben.',
    NO_PRODUCER_LABEL: 'Es wurde kein Stromproduzent gefunden. Bitte gib uns korrekte Daten mit.',
    TRY_AGAIN: 'Bitte versuchen Sie noch einmal'
};

export const PRIVACY_POLICY = {
    href: 'https://assets.ctfassets.net/wif4espsdh6s/1huNA6OBSyKJcaKkqJ123z/76b45d126691078782318d42967edb60/Datenschutzerkl__rung.pdf',
    label: 'Datenschutzerklärung'
};

export const SOCIAL_NETWORKS = {
    facebook: 'https://www.facebook.com/LitionEnergyDE',
    twitter: 'https://twitter.com/lition_energy',
    linkedin: 'https://www.linkedin.com/company/litionenergygmbh'
};

export const REGISTRATION_LABELS = {
    1: {
        title: 'Identität',
        formLabels: {
            title: 'Identität',
            submit: 'Weiter',
            businessOptions: ['Privat', 'Gewerbe'],
            companyPlaceholder: 'Name der Firma',
            companyLabel: 'Name der Firma',
            legalFormLabel: 'Rechtsform',
            legalFormPlaceholder: 'Rechtsform',
            salutationLabel: 'Anrede',
            salutationPlaceholder: 'Bitte auswählen',
            salutationOptions: ['Herr', 'Frau', 'Familie', 'Firma', 'Ohne'],
            firstNameLabel: 'Vorname',
            firstNamePlaceholder: 'Vorname',
            lastNameLabel: 'Nachname',
            lastNamePlaceholder: 'Nachname',
            birthdayLabel: 'Geburtsdatum',
            birthdayPlaceholder: 'Geburtsdatum',
            birthdayHelperText: 'Format: TT.MM.JJJJ',
            errors: {
                companyRequired: 'Firma eingeben.',
                legalFormRequired: 'Rechtsform eingeben.',
                salutationRequired: 'Anrede eingeben.',
                firstNameRequired: 'Vorname eingeben.',
                lastNameRequired: 'Nachname eingeben.',
                birthdayRequired: 'Geburtsdatum eingeben.',
                birthdayAdult: 'Du musst mindestens 18 Jahre alt sein, um einen Vertrag abzuschließen.'
            }
        }
    },
    2: {
        title: 'Kontakt',
        formLabels: {
            title: 'Kontakt',
            nextStep: 'Weiter',
            previousStep: 'Zurück',
            passwordHelperText: 'mind. 8 Zeichen mit einer Zahl und einem Großbuchstaben',
            fields: {
                email: 'Email',
                password: 'Passwort',
                passwordConfirmation: 'Passwort bestätigen',
                phoneAreaCode: 'Vorwahl',
                phone: 'Telefonnummer',
                existEmailAlert:
                    'Wie es aussieht bist du bereits Kunde bei uns. Möchtest du unter der selben Emailadresse eine weiter Lieferstelle hinzufügen?',
                newEmailAlert:
                    'Es gibt bereits einen Kunden mit dieser Emailadresse. Bist du dieser Kunde, und möchtest eine weitere Lieferstelle hinzufügen?',
                existAccountPassword: 'Bitte melde dich mit deinem Passwort an.',
                oldAccountOption:
                    'Ja, neue Lieferadresse hinzufügen und meine bestehende Kundennummer wiederverwenden.',
                oldAccountWithNewCNOption: 'Ja, neue Lieferadresse für eine neue Kundennummer hinzufügen.',
                newAccountOption: 'Nein, mit anderer Emailadresse registrieren.'
            },
            errors: {
                emailRequired: 'E-Mail eingeben.',
                emailType: 'E-Mail ist ungültig.',
                accountOptionRequired: 'Bitte wählen Sie eine Option',
                passwordValidationMessage:
                    'Das Passwort erfüllt nicht die erforderlichen Kriterien. Bitte wähle ein anderes Passwort.',
                passwordConfirmationRequired: 'Passwort bestätigen.',
                passwordConfirmationValidator: 'Passworte sollten übereinstimmen.'
            }
        }
    },
    3: {
        title: 'Anschrift',
        formLabels: {
            title: 'Anschrift',
            nextStep: 'Weiter',
            previousStep: 'Zurück',
            addressFieldsetTitle: 'Wohin sollen wir den Strom liefern?',
            addressFieldsetTitleGas: 'Wohin sollen wir das Gas liefern?',
            billingAddressFieldsetTitle: 'Welche Rechnungsanschrift sollen wir verwenden?',
            streetLabel: 'Strasse',
            streetPlaceholder: 'Strasse',
            streetNumberLabel: 'Hausnr.',
            streetNumberPlaceholder: 'Hausnr.',
            postcodeLabel: 'PLZ',
            postcodePlaceholder: 'PLZ',
            cityLabel: 'Ort',
            cityPlaceholder: 'Ort',
            houseOwner: 'Bist du Hauseigentümer?',
            billingAlternativeAddressValues: {
                true: 'Abweichende Rechnungsanschrift verwenden',
                false: 'Wie Adresse der Stromlieferung'
            },
            billingAlternativeAddressValuesGas: {
                true: 'Abweichende Rechnungsanschrift verwenden',
                false: 'Wie Adresse der Gaslieferung'
            },
            billingSalutationLabel: 'Anrede',
            billingSalutationPlaceholder: 'Bitte auswählen',
            // eslint-disable-next-line quote-props
            billingSalutationValues: { 1: 'Herr', 2: 'Frau', 3: 'Familie', 8: 'Firma', 9: 'Ohne' },
            billingFirstNameLabel: 'Vorname',
            billingFirstNamePlaceholder: 'Vorname',
            billingSurnameLabel: 'Nachname',
            billingSurnamePlaceholder: 'Nachname',
            billingStreetLabel: 'Strasse',
            billingStreetPlaceholder: 'Strasse',
            billingHouseNumberLabel: 'Hausnr.',
            billingHouseNumberPlaceholder: 'Hausnr.',
            billingZipLabel: 'PLZ',
            billingZipPlaceholder: 'PLZ',
            billingCityLabel: 'Ort',
            billingCityPlaceholder: 'Ort',
            errors: {
                streetRequired: 'Strasse eingeben.',
                streetNumberRequired: 'Hausnummer eingeben.',
                streetNumberInvalid: 'Bitte Ziffern und Buchstaben verwenden, z.B. 12a.',
                postcodeRequired: 'Postleitzahl eingeben.',
                postcodeInvalid: 'Bitte 5 oder 6 Ziffern eingeben.',
                cityRequired: 'Ort eingeben.',
                salutationRequired: 'Anrede eingeben.',
                firstNameRequired: 'Vorname eingeben.',
                surnameRequired: 'Nachname eingeben.',
                emailRequired: 'E-Mail eingeben.',
                emailInvalid: 'E-Mail ist ungültig.'
            }
        }
    },
    4: {
        title: 'Belieferung',
        formLabels: {
            title: 'Dein Jahresverbrauch',
            dateHelperText: 'Format: TT.MM.JJJJ',
            nextStep: 'Weiter',
            previousStep: 'Zurück',
            contractDataFieldsetTitle: 'Hast du bereits einen bestehenden Stromvertrag unter deiner Anschrift?',
            contractDataFieldsetTitleGas: 'Hast du bereits einen bestehenden Gasvertrag unter deiner Anschrift?',
            supplierDataFieldsetTitle: 'Bei wem warst du vorher?',
            contractTerminationFieldsetTitle: 'Ist der bisherige Vertrag bereits gekündigt?',
            usagePlaceholder: 'kWh',
            typeOfMeterId: 'Type of meter id',
            typeOfMeterIdOptions: ['Counter number', 'Market Location Identifier'],
            counterNumberLabel: 'Zählernummer',
            counterNumberPlaceholder: 'Zählernummer',
            marketLocationIdentifierLabel: 'Market Location Identifier',
            marketLocationIdentifierPlaceholder: 'Market Location Identifier',
            reduceEnergyCosts: 'Ja, bitte informiert mich über Möglichkeiten, meine Energiekosten deutlich zu senken.',
            energyStorage: 'Ja, ich möchte mehr über das Thema Stromspeicherung erfahren.',
            customerSpecificationLabels: {
                terminated_in_person: 'Ja, aber ich möchte zu stromee wechseln.',
                relocation_at: 'Nein. Ich ziehe dorthin um.'
            },
            customerSpecificationValues: {
                terminated_in_person: 'Lieferantenwechsel',
                relocation_at: 'Umzug'
            },
            previousProductLabel: 'Name des vorherigen Energieversorgers',
            previousProductIdPlaceholder: 'Vertragsnummer',
            contractTerminationValues: {
                true: 'Ja',
                false: 'Nein'
            },
            shouldCancelPreviousContractLabel: 'Soll stromee zum nächstmöglichen Termin kündigen?',
            shouldCancelPreviousContractValue: 'Ja',
            terminationDateLabel: 'Gekündigt zum',
            terminationDatePlaceholder: 'Datum',
            terminationDateAlert:
                'Hinweis: Bis zum Kündigungsdatum sind es nur noch {daysBeforeTermination} Werktage. Der Gesetzgeber gibt für den Wechsel 11 Werktage vor. Wir möchten dir einen problemlosen Wechsel zu stromee ermöglichen. Wegen des engen Zeitfensters können wir dir leider nicht garantieren, dass uns das gelingt. Sollte es zu einem späteren Lieferbeginn kommen, stellt dein Grundversorger die Belieferung sicher.',
            shouldCancelPreviousContractAlertLabel:
                'Hinweis: Hat dein jetziger Stromanbieter die Preise erhöht, und du hast ein Sonderkündigungsrecht? Dann kann diese Sonderkündigung nur durch dich als Vertragsnehmer erfolgen.',
            relocationDateLabel: 'Datum des Einzuges',
            relocationDatePlaceholder: 'Datum',
            errors: {
                usageRequired: 'Jahresverbrauch (kWh) eingeben.',
                usageNumber: 'Der Jahresverbrauch sollte zwischen 1000 und 100000 liegen.',
                counterNumberRequired: 'Zählernummer eingeben.',
                counterNumberPattern: 'Ungültige Zählernummer.',
                marketLocationIdentifierRequired: 'Market Location Identifier eingeben.',
                marketLocationIdentifierPattern: 'Ungültige Market Location Identifier.',
                relocationDateRequired: 'Umzugsdatum eingeben.',
                previousProductRequired: 'Vorversorger eingeben.',
                terminationDate: 'Datum eingeben.',
                shouldCancelPreviousContract: 'Genehmigung ist erforderlich.'
            }
        },
        consumptionOptions: [
            { value: '1600', label: '1 Person - 1600 kWh/Jahr ' },
            { value: '2400', label: '2 Personen - 2400 kWh/Jahr' },
            { value: '3200', label: '3 Personen - 3200 kWh/Jahr' },
            { value: '4200', label: '4 Personen - 4200 kWh/Jahr' }
        ],
        consumptionOptionsGas: [
            { value: '7500', label: '50 m² - 7500 kWh/Jahr' },
            { value: '10500', label: '70 m² - 10500 kWh/Jahr' },
            { value: '15000', label: '100 m² - 15000 kWh/Jahr' },
            { value: '18750', label: '125 m² - 18750 kWh/Jahr' },
            { value: '36000', label: '240 m² - 36000 kWh/Jahr' }
        ]
    },
    5: {
        title: 'Zahlweise',
        formLabels: {
            title: 'Zahlweise',
            nextStep: 'Weiter',
            previousStep: 'Zurück',
            errors: {
                ibanRequired: 'IBAN eingeben.',
                ibanValidator: 'Gültige IBAN eingeben.',
                sepaApprovalValidator: 'Bitte der SEPA-Einzugsermächtigung zustimmen.'
            }
        }
    },
    6: {
        title: 'Bestätigung',
        formLabels: {
            title: 'Deine Angaben',
            submit: 'Kunde werden',
            previousStep: 'Zurück',

            identitySection: {
                title: 'Identität',
                company: 'Name der Firma',
                legalForm: 'Rechtsform',
                salutation: 'Anrede',
                // eslint-disable-next-line quote-props
                salutationValues: { 1: 'Herr', 2: 'Frau', 3: 'Familie', 8: 'Firma', 9: 'Ohne' },
                name: 'Name',
                birthday: 'Geburtsdatum'
            },
            contactSection: {
                title: 'Kontakt',
                email: 'E-mail',
                phone: 'Telefonnummer'
            },
            addressSection: {
                title: 'Anschrift',
                address: 'Lieferadresse',
                billingAddress: 'Rechnungsadresse'
            },
            consumptionSection: {
                title: 'Belieferung',
                usage: 'Jahresverbrauch',
                counterNumber: 'Zählernummer',
                customerSpecification: 'Lieferantenwechsel',
                customerSpecificationValues: {
                    terminated_in_person: 'Ja',
                    relocation_at: 'Nein'
                },
                previousProduct: 'Vorheriger Energieversorger',
                previousProductId: 'Vorherige Vertragsnummer',
                terminationDate: 'Kündigungsdatum',
                relocationDate: 'Datum des Einzuges'
            },
            paymentInformationSection: {
                title: 'Zahlungsdetails',
                paymentMethod: 'Zahlweise',
                paymentMethodValues: {
                    debit: 'Lastschrift',
                    transfer: 'Überweisung',
                    cryptocurrency: 'Crypto-Währung'
                },
                iban: 'IBAN',
                alternativeAccountHolder: 'Name des Kontoinhabers',
                cryptocurrency: 'Crypto-Währung',
                cryptocurrencyValues: { bitcoin: 'Bitcoin', binanceToken: 'Binance Token (BNB)' }
            },

            fields: {
                referralCode: 'Ihr Empfehlungscode',
                message: 'Nachricht an uns',
                agbApproval:
                    'Mit <a href="//assets.ctfassets.net/4gjysvsltb3v/1NN1u9nhnoBWAC8Uzhnov2/2a92e4b75468a2ee69aa4c34270e6aaf/Lition_AGB_Strom_v23.pdf" rel="noopener noreferrer" target="_blank">AGBs</a>, <a href="//assets.ctfassets.net/wif4espsdh6s/RMVEol81GcO1PqfP0KnAn/724d66c44be6bb96026e356a4d0d6db5/Widerrufsformular.pdf" rel="noopener noreferrer" target="_blank">Widerruf</a> und <a href="//assets.ctfassets.net/4gjysvsltb3v/5XeDY8Im8iX1rbcfTGUSqf/14e0957557ba8a4629ef5d23757ac60a/Datenschutzerklaerung_v3.pdf" rel="noopener noreferrer" target="_blank">Datenschutzerklärung</a> einverstanden?',
                enableNotifications:
                    'Dürfen wir Dich über die neuen Entwicklungen und Produkte unserer Firma per E-Mail, per Telefon oder per Post, informieren?'
            },
            errors: {
                agbApprovalValidator: 'Bitte den AGB zustimmen.'
            }
        }
    }
};

export const REGISTRATION_BONUS_LABELS = {
    title: 'Bonusprogramm',
    formLabels: {
        title: 'Bonusprogramm',
        nextStep: 'Weiter',
        previousStep: 'Zurück',
        bonusProgramDecide: 'Hier kannst du deinen 25€ Gutschein auswählen.',
        bonusProgramExplanationText:
            'Dein Gutschein wird nach Ablauf des 14-tägigen Widerrufungsrechts deines Vertrags ausgestellt.',
        bonusProgramRightOfWithdrawalText: 'Du möchtest den Gutschein sofort erhalten? Einfach hier klicken:',
        mandsImage: {
            src: '//images.ctfassets.net/4gjysvsltb3v/3ZMuoDx5Kd7kvCq0BO7qzY/6eb83fdce9b920b65de10377478693e7/ms2.jpg',
            altText: ''
        },
        zajacImage: {
            src: '//images.ctfassets.net/4gjysvsltb3v/1YGal4GaKhONZB2iVa3aF4/b78950821d6a4fe9918d23b72a50bf8c/zoo_zajac.jpg',
            altText: ''
        },
        mandsLabelOnline: {
            title: '25€ Gutschein',
            text: 'Du erhälst deinen Gutschein per Email zum Einlösen im Zoo Zajac Online Shop.'
        },
        zajacLabelOnline: {
            title: '25€ Gutschein',
            text: 'Du erhälst deinen Gutschein per Email zum Einlösen im Zoo Zajac Online Shop.'
        },
        zajacLabelOffline: {
            title: '25€ Gutschein',
            text: 'Du erhälst deinen Gutschein per Email zum Einlösen im Zoo Zajac Online Shop.'
        }
    }
};

export const PRODUCER_ORDER_BY_OPTIONS = {
    price: 'Preis',
    savings: 'Ersparnis',
    distance: 'Distanz',
    name: 'Name'
};

export const GA_SIGNUP_FUNNAL_LABELS = [
    '1-Identity',
    '2-Contact',
    '3-Address',
    '4-Consumption',
    '5-Payment-Information',
    ['6-Confirmation', '6-Bonus-Program'],
    '7-Confirmation'
];

export const GOOD_ENERGY_CAMPAIGN_CONTENT_MODEL_ID = '1AlF1uimS7Z4LGY7gMHucv';

export const FOOTER_CONTENT_ID = '7KrEpy7OnWezG030ToKOyQ';
export const SIGNUP_PAGE_CONTENT_ID = '2rYBaqEt2xAnAhjwHltsOh';
export const PRODUCERS_PAGE_CONTENT_ID = '4UWGH8s0RSGxMT6k5KD3nB';
export const PRODUCER_DETAILS_PAGE_CONTENT_ID = '52bAlUHDKS2zSHBfGiJmXl';
export const DEFAULT_THANK_YOU_PAGE_CONTENT_ID = '39RrSQyIJ89TesmAEWTzjh';
export const NOT_FOUND_PAGE_ID = '6FFPEarMQTksxCcZo5mtIz';
export const ERROR_PAGE_ID = '1FLRzqOAjOVr2LN9T5UvIa';

export const STROMEE_ERROR_CODES = {
    USER_HAS_BAD_CREDITWORTHINESS: 13002,
    POWERCLOUD_DOWN: 13003
};

export const ENERGY_TYPES = {
    ELECTRICITY: 'electricity',
    GAS: 'gas',
    GAS_AND_ELECTRICITY: 'gas/electricity'
};

export const APP_ENV = { TEST: 'test', PRODUCTION: 'production', STAGING: 'staging', DEV: 'development' };

export const PAGE_ID = {
    STANDARD: 'standard',
    PRODUCERS: 'producers',
    PRODUCER_DETAILS: 'producer-details',
    CONTRACT: 'contract',
    SIGN_UP: 'signup',
    THANK_YOU: 'thankyou',
    SOLAR: 'solar',
    // Landing page for city
    REGIONAL_CAMPAIGN: 'regional_campaign',
    NOT_FOUND: '404'
};

export const SIGN_UP_FLOW = {
    STANDARD: 'standard'
};

/* lition-de-site 2.0 part */
// the new consumptionOptions and consumptionOptionsGas
export const CONSUMPTION_OPTIONS_ELECTRICITY = [
    { value: '1600' },
    { value: '2400' },
    { value: '3200' },
    { value: '4200' },
    { value: '5000' }
];

export const CONSUMPTION_OPTIONS_GAS = [
    { value: '7500' },
    { value: '15000' },
    { value: '22500' },
    { value: '30000' },
    { value: '37500' }
];

export const STANDARD_V2_CARD_CONTENT = {
    changeText: 'Kraftwerk täglich wechselbar auf unserem digitalen Energie-Marktplatz.',
    editContractText: 'Guter Service. Wir sind für Dich da.',
    bulbText: 'Wechselservice inklusive. Wir garantieren die ununterbrochene Belieferung'
};

export const PRODUCER_FILTER_OPTIONS = [
    { type: 'biomass', label: 'Bio-Erdgas' },
    { type: 'solar', label: 'Solarstrom' },
    { type: 'wind', label: 'Windkraft' }
];

export const APP_MODES = {
    V1: 'version1',
    V2: 'version2'
};

export const TARIFF_MODE = {
    POWER_PLANT: 'powerPlantTariffs',
    MARKETPLACE: 'marketplaceTariffs',
    PARTNER: 'partnerTariffs'
};

export const USER_AGENTS = {
    E2E: 'ltn-puppeteer'
};

export const DEFAULT_LOCALE = 'de';
export const ENGLISH_LOCALE = 'en';

export const EMAIL_REG_EXP_PATTERN = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

export const PHONE_REG_EXP_PATTERN = new RegExp(/^[-+ ()\d]{10,}$/i);

export const STANDARD_PRODUCER_IDS = { ELECTRICITY: '1', GAS: '50' };

export const HAVE_NO_PRODUCTS_MESSAGE =
    'Für diesen Verbrauch können wir in dieser PLZ leider keine Stromproduzenten anbieten. Sorry!';

export const STANDARD_PRODUCER_PICTURE =
    'https://images.ctfassets.net/wif4espsdh6s/4tuSkhgy2wym8H4vOnsBqV/aa16fc781cf6f61deb46c7d6efbdc5ad/mix_hell.jpg';

export const STYLE_PROPERTIES = {
    flex: {
        start: 'flex-start',
        center: 'center',
        end: 'flex-end'
    }
};

export const SCREEN_BREAKPOINTS = {
    mobile: { maxWidth: 560 },
    tablet: { maxWidth: 768 },
    laptop: { maxWidth: 1440 }
};

export const FIELD_OPTIONS = {
    MANDATORY: 'mandatory',
    OPTIONAL: 'optional',
    HIDDEN: 'hidden'
};

export const WIDGETS = {
    STROMEE_FRESH_CHAT: 'stromeeFreshchat',
    SUPPORT_REQUEST: 'supportRequest'
};

export const SORT_DIRECTION = {
    ASC: 'ASC',
    DESC: 'DESC'
};

export const DEFAULT_CONSUMPTION = '2500';
export const DEFAULT_ZIP = '10777';
export const DEFAULT_ENERGY_TYPE = ENERGY_TYPES.ELECTRICITY;
export const DEFAULT_ORDER_BY = 'price';
export const DEFAULT_SORT_DIRECTION = SORT_DIRECTION.ASC;
export const DEFAULT_PAGE_ID = PAGE_ID.STANDARD;
export const DEFAULT_SIGN_UP_FLOW = SIGN_UP_FLOW.STANDARD;
export const DEFAULT_MARKETPLACE_ID = '1';
export const DEFAULT_FILTER_BY = PRODUCER_TYPES.default; // TODO: Need check the logic. Rename to filter_by_producer_type or somethings like this @Anton

export const DEFAULT_PRODUCERS_LINK = '/producers';

export const HEAT_ENERGY_SUB_TYPE_IDS = {
    pump: {
        single: 5,
        double: 6
    },
    nightStorageHeating: {
        single: 9,
        double: 8
    },
    doubleTariff: 7
};

export const MOBILITY_ENERGY_SUB_TYPE_IDS = {
    mobility: 10,
    home: 11,
    double: 12
};

export const STYLE_GAP_SIZES = {
    SMALL: 'small',
    STANDARD: 'standard',
    LARGE: 'large'
};

export const PRODUCT_CERTIFICATE_NAMES = {
    OK_POWER: 'okPower',
    TUV_NORD: 'tuvNord'
};

export const HOME_PAGE_PATH = '/';
export const SOLAR_PAGE_PATH = `/${PAGE_ID.SOLAR}`;
export const PRODUCERS_PAGE_PATH = `/${PAGE_ID.PRODUCERS}`;
export const PRODUCER_DETAILS_PAGE_PATH = `/${PAGE_ID.PRODUCER_DETAILS}`;
export const CONTRACT_PAGE_PATH = `/${PAGE_ID.CONTRACT}`;
export const SIGN_UP_PAGE_PATH = `/${PAGE_ID.SIGN_UP}`;
export const THANK_YOU_PAGE_PATH = `/${PAGE_ID.THANK_YOU}`;

export const SECTION_NAMES = {
    SEASONAL: 'seasonal',
    CENTERED: 'centered',
    SEPARATED_BLOCKS: 'separatedBlocks',
    DARK_STANDARD: 'darkStandard',
    ADDITIONAL_INFO: 'additionalInfo',
    FEATURES: 'features',
    STATISTIC: 'statistic',
    FACT: 'fact',
    BADGES: 'badges',
    STANDARD_MEDIA: 'standardMedia',
    BLOCKQUOTE: 'blockquote',
    PARALLAX: 'parallax',
    TOP_INDEX: 'topIndex',
    TARIFF_ADVANTAGE_POINT: 'tariffAdvantagePoint',
    FAQ: 'FAQ',
    TWO_COLUMN_ARTICLE: 'twoColumnArticle',
    PICTURE: 'picture',
    BONUS_CARDS: 'bonusCards',
    SOLAR_TARIFF_CALCULATOR: 'solarTariffCalculator',
    SWITCH_TRANSITION: 'switchTransition',
    FINANCIAL_ADVISOR: 'financialAdvisor',
    SHARE_REFERRAL_CODE: 'shareReferralCode',
    PRESENTATION: 'presentation',
    PRODUCT_ADVANTAGES: 'productAdvantages',
    ADVANTAGE_POINTS: 'advantagePoints',
    TARIFF_CALCULATOR: 'tariffCalculatorSection',
    PARTNER: 'partnerSection',
    PARTNER_V2: 'partnersV2',
    MEDIA_POST: 'mediaPostSection',
    CREDENTIALS: 'credentialsSection',
    FEATURED_PRODUCERS: 'featuredProducersSection',
    ADVANTAGES: 'advantagesSection',
    ADVANTAGES_V2: 'advantagesV2',
    ICON_LINES: 'iconLines'
};

export const FORM_NAMES = {
    TARIFF_CALCULATION_FORM: 'tariffCalculationForm',
    SALES_AGENT_FORM: 'salesAgentForm'
};

export const COMPONENT_NAMES = {
    page: 'page',
    pageSection: 'pageSection',
    htmlContent: 'htmlContent',
    container: 'container',
    media: 'media',
    items: 'items',
    button: 'button',
    tariffCalculator: 'tariffCalculator',
    staticSection: 'staticSection'
};

export const CALC_FORM_MODES = {
    ZIP: 'zip',
    ADDRESS: 'address',
    NONE: 'none'
};
