import Schema from 'async-validator';
import isEmpty from 'lodash/isEmpty';

export function validateAddressParameters(zip, streetName, houseNumber) {
    if (!zip) {
        return 'Validation: Zip is required to retrieve pricing.';
    }
    if ((streetName && !houseNumber) || (!streetName && houseNumber)) {
        return 'Validation: Street name and house number are both required to retrieve address based pricing.';
    }

    return null;
}

export function validateFormData(descriptor, field, done) {
    const validator = new Schema({ [field.name]: descriptor[field.name] });

    validator.validate({ [field.name]: field.value }, error => {
        if (error) {
            return done(error);
        }

        return done(null);
    });
}

export function validateFormSubmitData(descriptor, fields, done) {
    const validator = new Schema(descriptor);

    validator.validate(fields, errors => {
        if (errors) {
            const fieldErrors = errors
                .map(item => ({ [item.field]: item.message }))
                .reduce((prevValue, currentValue) => ({ ...prevValue, ...currentValue }));

            return done(fieldErrors);
        }

        return done(null);
    });
}

export function isBlank(value) {
    switch (typeof value) {
        case 'boolean':
            return false;
        case 'function':
            return false;
        case 'string':
            return isEmpty(value.trim());
        case 'number':
            return Number.isNaN(value);
        default:
            return isEmpty(value);
    }
}
